import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },

    // Corporate
    {
      path: '/user/corporate',
      name: 'user-corporate',
      component: () => import('@/views/user/corporate/Index.vue'),
      meta: {
        pageTitle: 'User Corporate',
        breadcrumb: [
          { text: 'Manage User' },
          { text: 'User Corporate', active: true },
        ],
      },
    },
    {
      path: '/user/corporate/add',
      name: 'user-corporate',
      component: () => import('@/views/user/corporate/Add.vue'),
      meta: {
        pageTitle: 'Add Corporate',
        breadcrumb: [
          { text: 'Manage User' },
          { text: 'User Corporate' },
          { text: 'Add', active: true },
        ],
      },
    },
    {
      path: '/user/corporate/:id',
      name: 'user-corporate',
      component: () => import('@/views/user/corporate/Detail.vue'),
      meta: {
        pageTitle: 'Corporate Detail',
        breadcrumb: [
          { text: 'Manage User' },
          { text: 'User Corporate' },
          { text: 'Detail', active: true },
        ],
      },
    },
    {
      path: '/user/corporate/edit/:id',
      name: 'user-coprorate-detail',
      component: () => import('@/views/user/corporate/Edit.vue'),
      meta: {
        pageTitle: 'Edit Corporate',
        breadcrumb: [
          { text: 'Manage Corporate' },
          { text: 'User Corporate' },
          { text: 'Edit Corporate', active: true },
        ],
      },
    },

    // Member
    {
      path: '/user/member',
      name: 'user-member',
      component: () => import('@/views/user/member/Index.vue'),
      meta: {
        pageTitle: 'User Member',
        breadcrumb: [
          { text: 'Manage User' },
          { text: 'User Member', active: true },
        ],
      },
    },
    {
      path: '/user/member/add',
      name: 'user-member',
      component: () => import('@/views/user/member/Add.vue'),
      meta: {
        pageTitle: 'Add Member',
        breadcrumb: [
          { text: 'Manage User' },
          { text: 'User Member' },
          { text: 'Add', active: true },
        ],
      },
    },
    {
      path: '/user/member/import',
      name: 'user-member',
      component: () => import('@/views/user/member/Import.vue'),
      meta: {
        pageTitle: 'Import Member',
        breadcrumb: [
          { text: 'Manage User' },
          { text: 'User Member' },
          { text: 'Import', active: true },
        ],
      },
    },
    {
      path: '/user/member/bulk-update',
      name: 'user-member',
      component: () => import('@/views/user/member/BulkUpdate.vue'),
      meta: {
        pageTitle: 'Bulk Update Member',
        breadcrumb: [
          { text: 'Manage User' },
          { text: 'User Member' },
          { text: 'Bulk Update', active: true },
        ],
      },
    },
    {
      path: '/user/member/detail/:id',
      name: 'user-member',
      component: () => import('@/views/user/member/Detail.vue'),
      meta: {
        pageTitle: 'User Member Detail',
        breadcrumb: [
          { text: 'Manage User' },
          { text: 'User Member' },
          { text: 'Detail', active: true },
        ],
      },
    },
    {
      path: '/user/member/edit/:id',
      name: 'user-member',
      component: () => import('@/views/user/member/Edit.vue'),
      meta: {
        pageTitle: 'Edit Member',
        breadcrumb: [
          { text: 'Manage Member' },
          { text: 'User Member' },
          { text: 'Edit Member', active: true },
        ],
      },
    },

    // Divisi
    // {
    //   path: '/division',
    //   name: 'division',
    //   component: () => import('@/views/division/Index.vue'),
    //   meta: {
    //     pageTitle: 'Manage Division',
    //     breadcrumb: [
    //       { text: 'Manage Division', active: true },
    //     ],
    //   },
    // },

    // Leaderboard
    {
      path: '/leaderboard/transaction',
      name: 'leader-top-transaction',
      component: () => import('@/views/leaderboard/TopTransaction.vue'),
      meta: {
        pageTitle: 'Top Transaction',
        breadcrumb: [
          { text: 'Leaderboard' },
          { text: 'Top Transaction', active: true },
        ],
      },
    },
    {
      path: '/leaderboard/reseller',
      name: 'leader-top-reseller',
      component: () => import('@/views/leaderboard/TopReseller.vue'),
      meta: {
        pageTitle: 'Top Reseller',
        breadcrumb: [
          { text: 'Leaderboard' },
          { text: 'Top Reseller', active: true },
        ],
      },
    },
    {
      path: '/leaderboard/group',
      name: 'leader-top-group',
      component: () => import('@/views/leaderboard/TopGroup.vue'),
      meta: {
        pageTitle: 'Top Group',
        breadcrumb: [
          { text: 'Leaderboard' },
          { text: 'Top Group', active: true },
        ],
      },
    },
    {
      path: '/leaderboard/group/:id',
      name: 'leader-top-group',
      component: () => import('@/views/leaderboard/TopGroupDetail.vue'),
      meta: {
        pageTitle: 'Detail',
        breadcrumb: [
          { text: 'Leaderboard' },
          { text: 'Top Group' },
          { text: 'Detail', active: true },
        ],
      },
    },

    // Partner
    {
      path: '/partner',
      name: 'partner',
      component: () => import('@/views/partner/master/Index.vue'),
      meta: {
        pageTitle: 'Partner',
        breadcrumb: [
          { text: 'Partner' },
          { text: 'Master Partner', active: true },
        ],
      },
    },
    {
      path: '/partner/add',
      name: 'partner',
      component: () => import('@/views/partner/master/Add.vue'),
      meta: {
        pageTitle: 'Add Partner',
        breadcrumb: [
          { text: 'Partner' },
          { text: 'Add', active: true },
        ],
      },
    },
    {
      path: '/partner/edit/:id',
      name: 'partner',
      component: () => import('@/views/partner/master/Edit.vue'),
      meta: {
        pageTitle: 'Edit Partner',
        breadcrumb: [
          { text: 'Partner' },
          { text: 'Edit', active: true },
        ],
      },
    },
    {
      path: '/partner-tracking',
      name: 'partner-tracking',
      component: () => import('@/views/partner/Tracking.vue'),
      meta: {
        pageTitle: 'Partner Tracking',
        breadcrumb: [
          { text: 'Partner' },
          { text: 'Partner Tracking', active: true }
        ],
      },
    },
    {
      path: '/partner-tracking/:id',
      name: 'partner-tracking',
      component: () => import('@/views/partner/TrackingDetail.vue'),
      meta: {
        pageTitle: 'Detail',
        breadcrumb: [
          { text: 'Partner' },
          { text: 'Partner Tracking' },
          { text: 'Detail', active: true }
        ],
      },
    },

    // Banner
    {
      path: '/banner',
      name: 'banner',
      component: () => import('@/views/banner/Index.vue'),
      meta: {
        pageTitle: 'Manage Banner',
        breadcrumb: [
          { text: 'Manage Banner', active: true }
        ],
      },
    },
    {
      path: '/banner/add',
      name: 'banner',
      component: () => import('@/views/banner/Add.vue'),
      meta: {
        pageTitle: 'Manage Banner',
        breadcrumb: [
          { text: 'Manage Banner' },
          { text: 'Add', active: true }
        ],
      },
    },
    {
      path: '/banner/edit/:id',
      name: 'banner',
      component: () => import('@/views/banner/Edit.vue'),
      meta: {
        pageTitle: 'Manage Banner',
        breadcrumb: [
          { text: 'Manage Banner' },
          { text: 'Edit', active: true }
        ],
      },
    },

    // Event
    {
      path: '/event',
      name: 'event',
      component: () => import('@/views/event/Index.vue'),
      meta: {
        pageTitle: 'Manage Event',
        breadcrumb: [
          { text: 'Manage Event', active: true  }
        ],
      },
    },
    {
      path: '/event/add',
      name: 'event',
      component: () => import('@/views/event/Add.vue'),
      meta: {
        pageTitle: 'Add',
        breadcrumb: [
          { text: 'Manage Event' },
          { text: 'Add', active: true }
        ],
      },
    },
    {
      path: '/event/assign-team',
      name: 'event-assign-team',
      component: () => import('@/views/event/AssignTeam.vue'),
      meta: {
        pageTitle: 'Assign Event Team',
        breadcrumb: [
          { text: 'Manage Event' },
          { text: 'Assign Event Team', active: true }
        ],
      },
    },
    {
      path: '/event/log',
      name: 'log-event',
      component: () => import('@/views/event/log/LogEvent.vue'),
      meta: {
        pageTitle: 'Log Event',
        breadcrumb: [
          { text: 'Manage Event' },
          { text: 'Log Event', active: true }
        ],
      },
    },
    {
      path: '/event/member-log',
      name: 'log-member',
      component: () => import('@/views/event/log/LogMember.vue'),
      meta: {
        pageTitle: 'Log Member',
        breadcrumb: [
          { text: 'Manage Event' },
          { text: 'Log Member', active: true }
        ],
      },
    },
    {
      path: '/event/member-log/:id',
      name: 'log-member',
      component: () => import('@/views/event/log/LogMemberDetail.vue'),
      meta: {
        pageTitle: 'Log Member',
        breadcrumb: [
          { text: 'Manage Event' },
          { text: 'Log Member' },
          { text: 'Log Member Detail', active: true }
        ],
      },
    },
    {
      path: '/event/edit/:id',
      name: 'event',
      component: () => import('@/views/event/Edit.vue'),
      meta: {
        pageTitle: 'Edit',
        breadcrumb: [
          { text: 'Manage Event' },
          { text: 'Edit', active: true }
        ],
      },
    },
    {
      path: '/event/:id',
      name: 'event',
      component: () => import('@/views/event/Detail.vue'),
      meta: {
        pageTitle: 'Event Detail',
        breadcrumb: [
          { text: 'Manage Event' },
          { text: 'Event Detail', active: true }
        ],
      },
    },
    {
      path: '/log-corporate',
      name: 'log-corporate',
      component: () => import('@/views/log/LogCorporate.vue'),
      meta: {
        pageTitle: 'Log Corporate',
        breadcrumb: [
          { text: 'Log Corporate', active: true }
        ],
      },
    },
    // Manage Survey Form
    {
      path: '/questioner',
      name: 'questioner',
      component: () => import('@/views/survey/Index.vue'),
      meta: {
          breadcrumb: [
              { text: 'Home', url: '/home' },
              { text: 'Manage Questioner', active: true }
          ],
          pageTitle: 'Manage Questioner',
      }
    },
    {
      path: '/questioner/add',
      name: 'add-questioner',
      component: () => import('@/views/survey/Add.vue'),
      meta: {
          breadcrumb: [
              { text: 'Home', url: '/home' },
              { text: 'Manage Questioner', url: '/survey' },
              { text: 'Add', active: true }
          ],
          pageTitle: 'Add Questioner',
      }
    },
    {
      path: '/questioner/edit/:id',
      name: 'edit-questioner',
      component: () => import('@/views/survey/Edit.vue'),
      meta: {
          breadcrumb: [
              { text: 'Home', url: '/home' },
              { text: 'Manage Questioner', url: '/survey' },
              { text: 'Edit', active: true }
          ],
          pageTitle: 'Edit Questioner',
      }
    },
    {
      path: '/questioner/detail/:id',
      name: 'detail-questioner',
      component: () => import('@/views/survey/Detail.vue'),
      meta: {
          breadcrumb: [
              { text: 'Home', url: '/home' },
              { text: 'Manage Questioner', url: '/survey' },
              { text: 'Detail', active: true }
          ],
          pageTitle: 'Detail Questioner',
      }
    },
    // Manage Polling
    {
      path: '/polling',
      name: 'polling',
      component: () => import('@/views/polling/Index.vue'),
      meta: {
          breadcrumb: [
              { text: 'Home', url: '/home' },
              { text: 'Manage Polling', active: true }
          ],
          pageTitle: 'Manage Polling',
      }
    },
    {
      path: '/polling/detail/:id',
      name: 'detail-polling',
      component: () => import('@/views/polling/Detail.vue'),
      meta: {
          breadcrumb: [
              { text: 'Home', url: '/home' },
              { text: 'Manage Polling', url: '/polling' },
              { text: 'Detail', active: true }
          ],
          pageTitle: 'Detail Polling',
      }
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
